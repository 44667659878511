import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';

function CardBox({ card, updateCard, removeCard }) {
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box width="300px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateCard(card);
                }}>
                    Düzenle
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeCard(card);
                }}>
                    Sil
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={21} right={21}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{ background: "white" }}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box
                        component={"img"}
                        src={card.image_link}
                        alt={card.name}
                        width="100%"
                    />
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Typography variant='h5' fontWeight="500">
                    <b>{card.bank_name}</b> - {card.name}
                </Typography>
            </Box>
        </Box>
    )
}

export default CardBox