import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function CardDialog({ targetCard, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        name: targetCard?.name ? targetCard?.name : "",
        bank_name: targetCard?.bank_name ? targetCard?.bank_name : "",
        image_link: targetCard?.image_link ? targetCard?.image_link : "",
        cardImage: null
    };

    var validationObject = {
        name: yup.string().required("zorunlu"),
        bank_name: yup.string().required("zorunlu"),
        cardImage: yup.string().nullable().when('image_link', {
            is: (image_link) => !image_link || image_link === "",
            then: () => yup.mixed().required('zorunlu')
        }),
        image_link: yup.string()
    }

    const cardSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetCard ? "Kartı Düzenle" : "Yeni Kart Ekle"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={cardSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" width="400px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <Box gridColumn="span 6" textAlign="center" mt="50px">
                                        <Typography variant="h6">Kart Görseli<br />(800 x 400)</Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[500], height: "100px", width: "200px" }}>
                                            {!values.cardImage && !values.image_link && !values.image_link !== "" && <Typography variant="h6">Yükle</Typography>}
                                            {((values.cardImage) || (values.image_link && values.image_link !== "")) && <img alt='card logo' height="90px" src={values.cardImage ? URL.createObjectURL(values.cardImage) : values.image_link} />}
                                            <input id="cardImage" name="cardImage" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("cardImage", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Banka Adı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"bank_name"}
                                        value={values.bank_name}
                                        error={!!touched.bank_name && !!errors.bank_name}
                                        helperText={touched.bank_name && errors.bank_name}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kart Adı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CardDialog