import { Box, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import UserDialog from './UserDialog';
import { useNavigate } from 'react-router-dom';

function UsersPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [searchId, setSearchId] = useState(null);
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [targetUser, setTargetUser] = useState(null);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const updateUser = async (userId, user) => {
        var userIx = users.findIndex(u => u.id === userId);
        var profilePhotoLink;
        if (user.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadUserProfilePhoto?token=${token}`, {
                profilePhoto: user.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        profilePhotoLink = user.profile_photo_link.slice(0, user.profile_photo_link.indexOf("/images") + 1) + response.data.contentPath;
                        user.profile_photo_path = response.data.contentPath;
                    }
                });
        }



        var userToUpdate = { ...user };
        delete userToUpdate.photo;
        delete userToUpdate.profile_photo_link;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?token=${token}&userId=${userId}`, {
            user: userToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => {
                            var newUsers = [...prev];
                            if (profilePhotoLink) {
                                userToUpdate.profile_photo_link = profilePhotoLink;
                            } else {
                                userToUpdate.profile_photo_link = newUsers[userIx].profile_photo_link;
                            }
                            newUsers[userIx] = { ...newUsers[userIx], ...userToUpdate };
                            return newUsers;
                        })
                    }
                    if (editUserDialogOpen) {
                        setEditUserDialogOpen(false);
                    }
                }
            });
    }

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'profile_photo_link', headerName: "Profil Fotoğrafı", sortable: false, renderCell: (params) => <img src={params.value} alt='profile-pic' style={{ height: "30px", width: "30px" }} />, width: 70 },
        {
            field: 'name',
            headerName: 'İsim'
        }, {
            field: 'surname',
            headerName: 'Soyisim'
        }, {
            field: 'email',
            headerName: 'E-Posta'
        }, {
            field: 'phone_number',
            headerName: 'Telefon No'
        }, {
            field: 'gender',
            headerName: 'Cinsiyet',
            renderCell: (params) => { return params.value === 0 ? "Kadın" : params.value === 1 ? "Erkek" : "Gizli" }
        }, {
            field: 'latitude',
            headerName: 'Enlem'
        }, {
            field: 'longitude',
            headerName: 'Boylam'
        }, {
            field: 'distance',
            headerName: 'Mesafe Filtresi'
        }, {
            field: 'is_notifiable',
            headerName: 'Bildirim izni',
            type: "boolean"
        }, {
            field: "birth_date", headerName: "Doğum Tarihi",
            renderCell: ({ row: { birth_date } }) => {
                return new Date(birth_date).toLocaleDateString()
            }
        }, {
            field: "created_date", headerName: "Kayıt Tarihi",
            renderCell: ({ row: { created_date } }) => {
                return new Date(created_date).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                // <GridActionsCellItem
                //     icon={<InfoIcon />}
                //     label="Kullanıcı Detayı"
                //     onClick={() => navigate(`/admin-panel/users/${params.row.id}`)}
                //     showInMenu
                // />,
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Kullanıcıyı Düzenle"
                    onClick={() => setTargetUser(params.row)}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUsers?token=${token}&startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${searchId ? `&searchId=${searchId}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setUsers([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUsers(response.data.users);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel]);

    useEffect(() => {
        if (targetUser) {
            setEditUserDialogOpen(true);
        }
    }, [targetUser]);

    const handleEditUserDialogClose = () => {
        setTargetUser(null);
        setEditUserDialogOpen(false);
    }

    return (
        <Box sx={{ p: "75px" }}>
            <UserDialog dialogOpen={editUserDialogOpen} handleClose={handleEditUserDialogClose} targetUser={targetUser} handleFormSubmit={(values) => updateUser(targetUser.id, values)} />
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Kullanıcılar" subtitle="Bu sayfadan kullanıcıları görebilir ve yönetebilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Ara"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box-id">Ara(Id)</InputLabel>
                        <OutlinedInput
                            id="search-box-id"
                            type='number'
                            onChange={(e) => {
                                setSearchId(e.target.value ? e.target.value : "");
                            }}
                            label="Ara(Id)"
                        />
                    </FormControl>

                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                onRowDoubleClick={(row) => {
                    // navigate(`/admin-panel/users/${row.id}`);
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    }
                }}
            />
        </Box>
    )
}

export default UsersPage