import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../global/Header';
import axios from 'axios';
import { useContext } from 'react';
import { AdminContext } from '../../context/AdminContext';
import { Formik } from 'formik';
import * as yup from "yup";
import CardBox from './CardBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import CardDialog from './CardDialog';

function CardsPage() {
    const { token } = useContext(AdminContext);
    const [cards, setCards] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [searchKey, setSearchKey] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetCard, setTargetCard] = useState(null);

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [cardToRemove, setCardToRemove] = useState(null);

    const fetchCards = (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCards?token=${token}&searchKey=${searchKey}&startIndex=${startIndex}&limit=20`)
            .catch((err) => {
                console.log("err: " + err);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.cards && response.data.cards.length === 20);
                    if (startIndex === 0) {
                        setCards([...response.data.cards]);
                    } else {
                        setCards((prev) => [...prev, ...response.data.cards]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchCards(0);
    }, []);

    const handleFormSubmit = async (values) => {
        console.log(values);
        console.log(values.cardImage);
        if (values.cardImage) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCardImage?token=${token}`, {
                cardImage: values.cardImage
            }, {
                headers: { "Content-Type": "multipart/form-data" },
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    values.image_path = response.data.contentPath;
                }
            });
        }
        if (targetCard) {
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateCard?token=${token}&cardId=${targetCard.id}`, {
                card: {
                    name: values.name,
                    bank_name: values.bank_name,
                    ...(values.image_path ? { image_path: values.image_path } : {})
                }
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchCards(0);
                    handleClose();
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createCard?token=${token}`, {
                name: values.name,
                bank_name: values.bank_name,
                image_path: values.image_path
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchCards(0);
                    handleClose();
                }
            });
        }
    }

    useEffect(() => {
        if (targetCard) {
            setDialogOpen(true);
        }
    }, [targetCard]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetCard(null);
    }

    const updateCard = (card) => {
        setTargetCard(card);
    }


    useEffect(() => {
        if (cardToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [cardToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setCardToRemove(null);
    }

    const handleCardToRemove = (card) => {
        if (card) {
            setCardToRemove(card);
        }
    }

    const removeCard = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteCard?cardId=${cardToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                fetchCards(0);
                handleRemoveDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <CardDialog targetCard={targetCard} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Kartı silmek istediğinizden emin misiniz?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Kart ve ilgili tüm veriler silinecek. Bu işlemi geri alamazsınız, dilerseniz aynı kartı tekrar ekleyebilirsiniz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>İptal</Button>
                    <Button onClick={() => removeCard()}>Sil</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Kartlar" subtitle="Bu sayfadan kartları görebilir ve yönetebilirsiniz." />

                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setDialogOpen(true);
                }} >
                    Yeni Kart Ekle
                </Button>

            </Box>
            <Box mt="30px">
                <Box>
                    <InfiniteScroll
                        dataLength={cards.length}
                        next={() => fetchCards(cards.length)}
                        hasMore={hasMore}
                        loader={<h4>Yükleniyor...</h4>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Tüm kartları görüntülüyorsunuz.</b>
                            </p>
                        }
                        style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                        {cards.map(card => <CardBox key={card.id} card={card} updateCard={updateCard} removeCard={handleCardToRemove} />)}
                    </InfiniteScroll>
                </Box>
            </Box>
        </Box>
    )
}

export default CardsPage
